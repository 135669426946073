<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrio un error al obtener los Productos Samplings {{ error }}
      </div>
    </b-alert>

    <div style="text-align: center; margin: 45vh 0" v-if="loading">
      <b-spinner label="Loading..." />
    </div>

    <b-row v-else>
      <b-col cols="12" md="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Productos Sampling</b-card-title>
            <b-button
              @click="onCreateClick"
              variant="primary"
              :disabled="loading"
            >
              Crear
            </b-button>
          </b-card-header>

          <b-card-body>
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Buscar</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Buscar"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table
              :rows="rows"
              :columns="columns"
              @on-row-click="onProductClick"
              :sort-options="{
                enabled: false,
              }"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Imagen -->
                <span v-if="props.column.field === 'imagen'">
                  <b-img
                    :src="urlResized(props.row.imagen)"
                    width="50"
                    height="50"
                    rounded
                  />
                </span>
                <!-- Column: Estado -->
                <span v-else-if="props.column.field === 'estado'">
                  <b-badge :variant="estadoVariant(props.row.estado)">
                    {{ estadoText(props.row.estado) }}
                  </b-badge>
                </span>
                <!-- Column: Otras Columnas -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Cantidad de Productos</span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10', '15', '20']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <crear-editar-producto
          v-if="selectedProduct !== null"
          :product="selectedProduct"
          :refreshData="getSamplingData"
        />
        <div v-else class="iconEmpty">
          <feather-icon icon="GiftIcon" size="100" />
        </div>
      </b-col>
    </b-row>
    <b-modal
      ok-only
      ok-variant="danger"
      ok-title="Accept"
      modal-class="modal-danger"
      centered
      v-model="showErrorModal"
      title="Error"
    >
      <b-card-text>
        {{ error }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BBadge,
  BAlert,
  BAvatar,
  BButton,
  BSpinner,
  BDropdown,
  BCardText,
  BCardBody,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BFormSelect,
  BPagination,
  BDropdownItem,
} from "bootstrap-vue";
import moment from "moment";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import CrearEditarProducto from "./CrearEditarProducto.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BBadge,
    BAlert,
    BAvatar,
    BButton,
    BSpinner,
    BDropdown,
    BCardText,
    BCardBody,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdownItem,
    CrearEditarProducto,
  },
  data() {
    return {
      rows: [],
      error: "",
      loading: true,
      pageLength: 10,
      searchTerm: "",
      showErrorModal: false,
      selectedProduct: null,
      columns: [
        {
          label: "Imagen",
          field: "imagen",
        },
        {
          label: "Nombre",
          field: "nombre",
        },
        {
          label: "Código",
          field: "codigo",
        },
        {
          label: "Estado",
          field: "estado",
        },
      ],
    };
  },
  computed: {
    estadoText() {
      return (s) => (s === "A" ? "Activo" : "Inactivo");
    },
    estadoVariant() {
      return (s) => (s === "A" ? "light-success" : "light-secondary");
    },
  },
  methods: {
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/50x50" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg";
      }
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalDate) {
      return moment(new Date(originalDate)).format("hh:mm a");
    },
    onCreateClick() {
      this.selectedProduct = {
        id: 0,
      };
    },
    onProductClick(params) {
      this.selectedProduct = null;
      const productAUX = JSON.parse(JSON.stringify(params.row));
      productAUX.activo = productAUX.estado === "A";
      productAUX.codigo = productAUX.codigo.replace("SPL0-", "");
      this.$nextTick(() => {
        this.selectedProduct = productAUX;
      });
    },
    getSamplingData() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetSamplingProducts",
          body: JSON.stringify({
            empresa: 2,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.rows = res.data.productos;
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.error = e.message;
          this.loading = false;
          this.showErrorModal = true;
        });
    },
  },
  created() {
    this.getSamplingData();
  },
};
</script>

<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
