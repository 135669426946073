var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.sidebar-sectores",
      modifiers: {
        "sidebar-sectores": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-secondary",
      "block": "",
      "disabled": _vm.loading
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Asignar bodegas")])], 1), _c('b-sidebar', {
    ref: "sidebar-sectores",
    attrs: {
      "id": "sidebar-sectores",
      "width": "40em",
      "bg-variant": "white",
      "shadow": "",
      "backdrop": "",
      "no-header": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "\n          d-flex\n          justify-content-between\n          align-items-center\n          content-sidebar-header\n          px-2\n          py-1\n        "
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_vm._v("Asignar bodegas")]), _c('feather-icon', {
          staticClass: "ml-1 cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "size": "16"
          },
          on: {
            "click": hide
          }
        })], 1), _c('b-form', {
          ref: "form",
          staticClass: "p-2 repeater-form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
            }
          }
        }, [_c('div', [_c('div', [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mb-3",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.repeateAgain
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "PlusIcon"
          }
        }), _c('span', [_vm._v("Agregar Asignacion")])], 1), _vm._l(_vm.asignaciones, function (asignacion, index) {
          return _c('b-row', {
            key: 'asignacion-' + index,
            ref: "row",
            refInFor: true,
            attrs: {
              "id": 'asignacion-' + index
            }
          }, [_c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Bodega",
              "label-for": 'vi-bodega-' + index
            }
          }, [_c('v-select', {
            attrs: {
              "id": 'vi-bodega-' + index,
              "label": "nombre",
              "options": _vm.bodegas,
              "reduce": function reduce(bodega) {
                return bodega.id;
              }
            },
            model: {
              value: asignacion.id,
              callback: function callback($$v) {
                _vm.$set(asignacion, "id", $$v);
              },
              expression: "asignacion.id"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "4"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Stock",
              "label-for": 'vi-stock-' + index
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": 'vi-stock-' + index,
              "type": "number",
              "step": "1",
              "placeholder": "Stock",
              "min": "0"
            },
            model: {
              value: asignacion.stock,
              callback: function callback($$v) {
                _vm.$set(asignacion, "stock", $$v);
              },
              expression: "asignacion.stock"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [_c('b-button', {
            staticClass: "btn-icon mt-1",
            attrs: {
              "id": 'vi-delete-' + index,
              "variant": "flat-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.removeItem(index);
              }
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "Trash2Icon",
              "size": "25"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('hr')])], 1);
        })], 2)]), _c('div', {
          staticClass: "d-flex mt-2"
        }, [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "primary",
            "type": "submit",
            "disabled": _vm.loading
          },
          on: {
            "click": _vm.saveData
          }
        }, [_vm.loading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "label": "Loading..."
          }
        }) : _c('div', [_vm._v("Guardar")])], 1), _c('b-button', {
          attrs: {
            "disabled": _vm.loading,
            "variant": "outline-secondary"
          },
          on: {
            "click": hide
          }
        }, [_vm._v(" Cancelar ")])], 1)])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }