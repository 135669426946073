<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{
          product.id === 0
            ? "Crear Producto Sampling"
            : "Modificar Producto Sampling"
        }}</b-card-title
      >
      <div>
        <b-form-group label="Activo" label-for="vi-activo">
          <b-form-checkbox
            id="vi-activo"
            v-model="product.activo"
            class="custom-control-primary"
            name="check-button"
            switch
          />
        </b-form-group>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="8">
          <b-form-group label="Nombre" label-for="vi-nombre">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="GiftIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="vi-nombre"
                placeholder="Nombre del item"
                v-model="product.nombre"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group label="Código" label-for="vi-codigo">
            <b-input-group>
              <b-input-group prepend="SPL0-">
                <b-form-input
                  id="vi-codigo"
                  placeholder="Código"
                  v-model="product.codigo"
                />
              </b-input-group>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Descripcion" label-for="vi-descripcion">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="BookIcon" />
              </b-input-group-prepend>
              <b-form-textarea
                id="vi-descripcion"
                v-model="product.descripcion"
                placeholder="Descripcion"
                rows="2"
                no-resize
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <div v-if="loadingImage" class="lImg">
            <b-spinner label="Loading..." />
          </div>
          <div v-else>
            <b-img :src="urlResized(product.imagen)" rounded fluid />
            <b-form-file
              class="mt-1"
              id="vi-file-in"
              placeholder="Imagen"
              accept=".jpg, .png"
              @change="encodeImageFile"
              browse-text="Seleccionar"
            />
          </div>
        </b-col>

        <b-col cols="12" class="mt-2">
          <sidebar-asignacion
            v-if="product.id && product.id !== 0"
            :product-id="product.id"
          />
        </b-col>

        <b-col cols="12" class="mt-2">
          <b-button
            block
            @click="saveData"
            variant="primary"
            class="mr-1"
            :disabled="loading || loadingImage"
          >
            <b-spinner v-if="loading" small label="Loading..." />
            <div v-else>Guardar</div>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>


<script>
import {
  BRow,
  BCol,
  BImg,
  BCard,
  BButton,
  BSpinner,
  BFormFile,
  BCardBody,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BInputGroup,
  BButtonGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormRadioGroup,
  BFormSpinbutton,
  BInputGroupPrepend,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import SidebarAsignacion from "./SidebarAsignacion.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BButton,
    vSelect,
    BSpinner,
    BCardBody,
    BCardText,
    BFormFile,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BInputGroup,
    BButtonGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    BFormSpinbutton,
    SidebarAsignacion,
    BInputGroupPrepend,
    ToastificationContent,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingImage: false,
    };
  },
  methods: {
    encodeImageFile(element) {
      const self = this;
      const filesSelected = element.currentTarget.files;
      if (filesSelected.length > 0) {
        const fileToLoad = filesSelected[0];
        const fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
          const srcData = fileLoadedEvent.target.result;
          self.uploadImage(srcData);
        };
        fileReader.readAsDataURL(fileToLoad);
      }
    },
    uploadImage(imagenSRC) {
      const self = this;
      self.loadingImage = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UploadProductSamplingImage",
          body: JSON.stringify({
            empresa: 2,
            Imagen: imagenSRC,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          self.loadingImage = false;
          if (res.data.bOk) {
            self.product.imagen = res.data.url;
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al subir la imagen (${res.data.mensaje}).`,
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          self.loadingImage = false;
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al subir la imagen (${e.message}).`,
              variant: "danger",
            },
          });
        });
    },
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/200x200" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg";
      }
    },
    saveData(e) {
      e.preventDefault();
      const productAUX = JSON.parse(JSON.stringify(this.product));
      productAUX.estado = productAUX.activo ? "A" : "I";
      productAUX.Codigo = "SPL0-" + productAUX.codigo;
      productAUX.empresa = 2;
      productAUX.tokenSesion = getAuthToken();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SamplingProducts",
          body: JSON.stringify(productAUX),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.refreshData();
            setTimeout(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Exito",
                  icon: "CheckIcon",
                  text:
                    this.product.id === 0
                      ? "Se creo el producto Sampling correctamente"
                      : "Se actualizo correctamente el producto sampling.",
                  variant: "success",
                },
              });
            }, 500);
            if (this.product.id === 0) {
              this.product.id = res.data.iDtemp;
            }
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text:
                self.product.id === 0
                  ? `Ocurrio un error al Crear el producto Sampling (${e.message}).`
                  : `Ocurrio un error al actualizar el producto Sampling (${e.message}).`,
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.iconEmpty {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lImg {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 12em;
}
</style>

