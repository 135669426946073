<template>
  <div>
    <b-button
      variant="outline-secondary"
      class="mb-75"
      block
      v-b-toggle.sidebar-sectores
      :disabled="loading"
    >
      <b-spinner v-if="loading" small label="Loading..." />
      <div v-else>Asignar bodegas</div>
    </b-button>
    <b-sidebar
      id="sidebar-sectores"
      width="40em"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      ref="sidebar-sectores"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Asignar bodegas</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-form class="p-2 repeater-form" @submit.prevent ref="form">
          <div>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="repeateAgain"
                class="mb-3"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Agregar Asignacion</span>
              </b-button>

              <b-row
                v-for="(asignacion, index) in asignaciones"
                :id="'asignacion-' + index"
                :key="'asignacion-' + index"
                ref="row"
              >
                <b-col md="6">
                  <b-form-group
                    label="Bodega"
                    :label-for="'vi-bodega-' + index"
                  >
                    <v-select
                      :id="'vi-bodega-' + index"
                      v-model="asignacion.id"
                      label="nombre"
                      :options="bodegas"
                      :reduce="(bodega) => bodega.id"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Stock" :label-for="'vi-stock-' + index">
                    <b-form-input
                      :id="'vi-stock-' + index"
                      v-model="asignacion.stock"
                      type="number"
                      step="1"
                      placeholder="Stock"
                      min="0"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-button
                    :id="'vi-delete-' + index"
                    variant="flat-danger"
                    class="btn-icon mt-1"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="Trash2Icon" size="25" />
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              @click="saveData"
              :disabled="loading"
            >
              <b-spinner v-if="loading" small label="Loading..." />
              <div v-else>Guardar</div>
            </b-button>
            <b-button
              :disabled="loading"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BButton,
  VBToggle,
  BFormDatepicker,
  BFormTimepicker,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";

import axios from "axios";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import { heightTransition } from "@core/mixins/ui/transition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BButton,
    VBToggle,
    BFormDatepicker,
    BFormTimepicker,
    BRow,
    BCol,
    vSelect,
    ToastificationContent,
    BSpinner,
    BFormInput,
  },
  mixins: [heightTransition],
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: "",
      loading: false,
      asignaciones: [],
      bodegas: [],
      nextBodegaID: 5616516,
    };
  },
  beforeMount() {},
  mounted() {
    this.initHeightSector();
    this.getBodegas();
    this.getAsignacionesData();
  },
  created() {
    window.addEventListener("resize", this.initHeightSector);
  },
  destroyed() {
    window.removeEventListener("resize", this.initHeightSector);
  },
  methods: {
    saveData() {
      this.loading = true;
      const self = this;
      axios
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SamplingProductsSetWarehouse",
          body: JSON.stringify({
            bodegas: self.asignaciones,
            productoID: self.productId,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          self.loading = false;
          if (res.data.bOk) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Exito",
                icon: "CheckIcon",
                text: "Se cambio correctamente las asignaciones.",
                variant: "success",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al cambiar las asignaciones (${res.data.mensaje}).`,
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al cambiar cambiar las asignaciones (${e.message}).`,
              variant: "danger",
            },
          });
          self.loading = false;
        });
    },
    getAsignacionesData() {
      const self = this;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetSamplingStock",
          body: JSON.stringify({
            empresa: 2,
            productoID: self.productId,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            self.asignaciones = res.data.bodegas;
          } else {
            self.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          self.error = e.message;
        });
    },
    getBodegas() {
      const self = this;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetWarehouse",
          body: JSON.stringify({
            empresa: 2,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            self.bodegas = res.data.bodegas;
          } else {
            self.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          self.error = e.message;
        });
    },
    repeateAgain() {
      if (!this.asignaciones.some((s) => s.id === 0)) {
        this.asignaciones.push({
          id: 0,
        });
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight);
        });
      }
    },
    removeItem(index) {
      this.asignaciones.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initHeightSector() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  computed: {},
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
