var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(" " + _vm._s(_vm.product.id === 0 ? "Crear Producto Sampling" : "Modificar Producto Sampling"))]), _c('div', [_c('b-form-group', {
    attrs: {
      "label": "Activo",
      "label-for": "vi-activo"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-activo",
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.product.activo,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "activo", $$v);
      },
      expression: "product.activo"
    }
  })], 1)], 1)], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "GiftIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "placeholder": "Nombre del item"
    },
    model: {
      value: _vm.product.nombre,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "nombre", $$v);
      },
      expression: "product.nombre"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Código",
      "label-for": "vi-codigo"
    }
  }, [_c('b-input-group', [_c('b-input-group', {
    attrs: {
      "prepend": "SPL0-"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-codigo",
      "placeholder": "Código"
    },
    model: {
      value: _vm.product.codigo,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "codigo", $$v);
      },
      expression: "product.codigo"
    }
  })], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Descripcion",
      "label-for": "vi-descripcion"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BookIcon"
    }
  })], 1), _c('b-form-textarea', {
    attrs: {
      "id": "vi-descripcion",
      "placeholder": "Descripcion",
      "rows": "2",
      "no-resize": ""
    },
    model: {
      value: _vm.product.descripcion,
      callback: function callback($$v) {
        _vm.$set(_vm.product, "descripcion", $$v);
      },
      expression: "product.descripcion"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_vm.loadingImage ? _c('div', {
    staticClass: "lImg"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('div', [_c('b-img', {
    attrs: {
      "src": _vm.urlResized(_vm.product.imagen),
      "rounded": "",
      "fluid": ""
    }
  }), _c('b-form-file', {
    staticClass: "mt-1",
    attrs: {
      "id": "vi-file-in",
      "placeholder": "Imagen",
      "accept": ".jpg, .png",
      "browse-text": "Seleccionar"
    },
    on: {
      "change": _vm.encodeImageFile
    }
  })], 1)]), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm.product.id && _vm.product.id !== 0 ? _c('sidebar-asignacion', {
    attrs: {
      "product-id": _vm.product.id
    }
  }) : _vm._e()], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "block": "",
      "variant": "primary",
      "disabled": _vm.loading || _vm.loadingImage
    },
    on: {
      "click": _vm.saveData
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }